<template>
  <div>
    <div class="fill-height background-style">
      <Navbar />
      <v-container class="px-5 px-sm-9 pb-0">
        <SearchBar />
      </v-container>
      <v-container class="px-0 px-md-2 pt-0 pb-5">
        <div
          class="white--text"
          :style="[
            $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
              ? { padding: '50px 40px' }
              : { padding: '70px 30px 20px 30px' },
          ]"
        >
          <p class="text-h2 text-md-h1 font-weight-bold">
            Planea tu evento de forma fácil y efectiva.
          </p>
        </div>
      </v-container>
    </div>
    <div
      :style="[
        $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
          ? { 'padding-top': '30px', 'padding-bottom': '10px' }
          : { 'padding-top': '50px', 'padding-bottom': '10px' },
      ]"
      class="body-container"
    >
      <v-container class="px-10 px-md-auto pb-md-0 mb-16">
        <div class="text-h3 font-weight-regular">
          <p class="text-h2 font-weight-bold secondary--text">
            ¿Quiénes somos?
          </p>
          <div
            :style="[
              $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
                ? { width: '100%' }
                : { width: '83%' },
            ]"
          >
            <br />
            En Zaturna queremos que vivas momentos inolvidables.
            <br />
            <br />
            Estamos conscientes de lo complejo que puede ser planear cualquier
            clase de eventos, además no existe alguna página que nos permita
            planear, organizar y contratar todos los servicios que se requieren
            dentro de un evento
            <br />
            <br />
          </div>

          <div>
            <div class="font-weight-bold">
              ¿Boda, Cumpleaños, XV Años o algún evento corporativo?
            </div>
            <br />
            Somos un marketplace que te permitirá planear cualquier clase de
            evento y encontrar a los mejores proveedores, contratar y olvidarte
            de cualquier preocupación que organizar un evento puede tener.
            <br />
            <br />
            Nuestra misión es que a través de nuestra plataforma la planeación
            sea más sencilla, divertida y con beneficios que solo en Zaturna
            podrás encontrar.
            <br />
            <br />
            Nos apasiona el celebrar, festejar y vivir cada momento especial,
            por eso en Zaturna contamos con cientos de empresas especializadas
            en proveer servicios para eventos y estamos seguros que encontrarás
            justo lo que necesitas
            <br />
            <br />
            Beneficios de Zaturna
            <br />
            <br />
            <ul>
              <li>Crea un Evento</li>
              <li>Selecciona Proveedores</li>
              <li>Califica el Servicio</li>
              <li>Contacta y Contrata desde nuestra Plataforma</li>
            </ul>
            <br />
            Facilitamos la organización de eventos para quienes vivirán el
            evento y quienes proveerán algún servicio.
            <br />
            <br />
            ¿Qué estas esperando para vivir Zaturna?
          </div>

          <br />
          <br />
          <div class="d-flex flex-column flex-md-row justify-space-around">
            <div>
              <Button
                text="Tengo que organizar un Evento"
                class="my-5 my-md-0"
                aria-label="Ir a la lista de servicios"
                url="Services"
              />
            </div>
            <div>
              <Button
                text="Soy empresa y quiero ser parte de Zaturna"
                class="my-5 my-md-0"
                aria-label="Ir a la página de información de proveedores"
                url="MainPageProviders"
              />
            </div>
          </div>
          <br />
          <br />
          <div>
            <ListCategories
              :zaturna-inspiration="false"
              title="Servicios para todo tipo de eventos"
            />
          </div>
        </div>
      </v-container>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/LandingPage/Shared/Navbar.vue";
import SearchBar from "@/components/Shared/SearchBar.vue";
import Button from "@/components/Shared/Button.vue";
import ListCategories from "../../components/LandingPage/MainPage/ListCategories.vue";
import Footer from "@/components/LandingPage/Shared/Footer.vue";
import { mapState } from "vuex";

export default {
  name: "WhoAreWePublic",
  components: {
    Navbar,
    SearchBar,
    Button,
    ListCategories,
    Footer,
  },
  computed: mapState({
    provider(state) {
      return state.provider;
    },
  }),
};
</script>

<style scoped lang="scss">
.background-style {
  min-height: 40vh;
  background: url("../../assets/img/WhoAreWe.png") no-repeat center center !important;
  background-size: cover !important;
}
</style>
